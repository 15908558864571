
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { rutEsValido, formatRut, yupIsRut } from "@/core/validators/YupRut";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      form: {
        rut: "",
        clave: "",
      },
    };
  },
  methods: {
    formatRut() {
      this.form.rut = formatRut(this.form.rut);
    },
  },
  setup() {
    const store = useStore();

    const submitButton = ref<HTMLButtonElement | null>(null);
    Yup.addMethod(Yup.string, "yupIsRut", function (mensaje) {
      const { message } = mensaje;
      return this.test("yupIsRut", message, function (value) {
        const { path, createError } = this;
        const { some, more, args } = mensaje;
        // [value] - value of the property being tested
        // [path]  - property name,
        // ...
        return rutEsValido(value);
      });
    });
    //Create form validation object
    const forgotPassword = Yup.object().shape({
      rut: Yup.string()
        .test("yupIsRut", "Rut ingresado no es valido", function (value) {
          return rutEsValido(value);
        })
        .required("Es requerido")
        .label("Rut"),
      email: Yup.string()
        .min(4)
        .required("Email es requerido")
        .email("El email no es válido")
        .label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: "Hemos enviado las instrucciones al correo electrónico",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getAuthErrors);
            // Alert then login failed
            Swal.fire({
              text: store.getters.getAuthErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intente Nuevamente",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 1);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
